import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./campaignForm.css";
import axios from "axios";
import { serviceTitanURL, ingeniuxURL } from "../../config/urls";
import { paintHeader, serviceError, aboutUs } from "./Constant.js";
import {
  regxEmail,
  regxUSPhone,
  regxUSZipcode,
  storeFranchiseDataToLS,
  deleteItemFromLS,
  getFranchiseDataFromLS,
  serviceTitanHeader,
} from "../../config/utility";
import { stateList } from "../../constants/stateList.js";

const CampaignForm = ({
  contentData,
  franchiseDetails,
  franchiseDataToGetEstimate: { franchiseId, franchiseName, stateAbbr } = {},
}) => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  const corpClientId = process.env.REACT_APP_CORP_CLIENTID;
  const corpEmailId = process.env.REACT_APP_JOTFORM_EMAIL;

  //States for all the input fields
  const [fullnameValue, setFullnameValue] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState("");
  const [zipCode, setZipCode] = useState();
  const [matchLoc, setMatchLoc] = useState("");
  const [zipLenErr, setZipLenErr] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isServiceDown, setIsServiceDown] = useState(false);
  const [serviceDownMessage, setServiceDownMessage] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");

  //States for validity check of the input fields
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isPopulatedAddress, setIsPopulatedAddress] = useState(true);
  const [isPopulatedCity, setIsPopulatedCity] = useState(true);
  const [isPopulatedState, setIsPopulatedState] = useState(true);
  const [formStart, setFormStart] = useState(false);
  const [isPopulatedProject, setIsPopulatedProject] = useState(true);
  const [franchiseContactNumber, setFranchiseContactNumber] =
    useState("1-855-PAINT80");

  //States for fields which are having error
  const [isErrorPhoneNo, setIsErrorPhoneNo] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorZipCode, setIsErrorZipCode] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isErrorCity, setIsErrorCity] = useState(false);
  const [isErrorStreetAddress, setIsErrorStreetAddress] = useState(false);

  //State for service titan id
  const [serviceTitanId, setServiceTitanId] = useState(null);

  const handleFirstNameChange = (e) => {
    setFullnameValue(e.target.value);
  };

  const handlePhoneChange = (e) => {
    let formattedValue = formatPhoneWithBracket(e.target.value);

    if (formattedValue.length > 14) {
      formattedValue = formattedValue.slice(0, 14);
    }

    setPhoneNumber(formattedValue);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleProjectChange = (e) => {
    setProjectValue(e.target.value);
  };
  const handleAddressChange = (e) => {
    setStreetAddress(e.target.value);
  };
  const handleStateChange = (e) => {
    setStateValue(e.target.value);
  };
  const handleCityChange = (e) => {
    setCityValue(e.target.value);
  };
  var matchingZipcode = "";

  const handleZipcodeChange = (e) => {
    e.preventDefault();

    let isValidZip;
    const zipVal = e.target.value;
    setZipCode(zipVal);

    isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipVal);

    if (!isValidZip || zipVal === "") {
      setZipLenErr(false);
    }

    for (let i = 0; i < contentData?.zipCodeList?.length; i++) {
      for (let j = 0; j < contentData?.zipCodeList[i]?.length; j++) {
        if (zipVal === contentData?.zipCodeList[i][j]) {
          matchingZipcode = zipVal;
        }
      }
    }
    if (!zipVal) setZipLenErr(false);
    else if (zipVal?.length === 5 && isValidZip) {
      //checking the LocalOffices array contains any match with user entered ZIPCODE
      const locationMatch = contentData?.localOfficeList?.filter(
        (office) =>
          office?.OwnedZipcodes?.some((zip) => zip === zipVal) ||
          office?.AllowedZipcodes?.some((zip) => zip === zipVal)
      );

      const zipLocationMatch =
        locationMatch && locationMatch[0]?.HomePageLink?.URL;
      const zipLocName = locationMatch && locationMatch[0]?.FranchiseName;
      zipLocationMatch &&
        setMatchLoc((prevState) => ({
          ...prevState,
          locUrl: zipLocationMatch,
          locName: zipLocName,
        }));
      if (zipLocationMatch)
        sessionStorage.setItem("locationMatch", zipLocationMatch);
      else sessionStorage.setItem("locationMatch", zipLocationMatch);
      //deleting the recent submission data in the case of no location match scenario
      if (zipLocationMatch) {
        storeFranchiseDataToLS(
          "recentEstimateSubmissionPath",
          `/${zipLocationMatch}`
        );
        // Call to fetch dynamic ServiceTitan Id from Franchises
        axios
          .get(`${ingeniuxURL}api/page?path=${zipLocationMatch}`)
          .then((res) => {
            const franchiseCorpControl =
              res?.data?.results[0]?.FranchiseHomePage
                ?.FranchiseCorporateControl;
            setServiceTitanId(franchiseCorpControl?.ServiceTitanID?.Value);
            setFranchiseContactNumber(franchiseCorpControl?.PhoneNumber?.Value);
          })
          .catch((err) => console.log(err));
      } else deleteItemFromLS("recentEstimateSubmissionPath");

      setZipLenErr(false);
    } else {
      setZipLenErr(true);
    }
  };

  const formatPhoneWithBracket = (phone) => {
    const x = phone?.replace(/\D/g, "");
    if (x.length > 3 && x.length < 7) {
      return "(" + x.slice(0, 3) + ") " + x.slice(3);
    } else if (x.length >= 7) {
      return "(" + x.slice(0, 3) + ") " + x.slice(3, 6) + "-" + x.slice(6);
    }
    return x;
  };
  const handleApiCalls = (formData) => {
    axios
      .post(serviceTitanURL, formData, serviceTitanHeader)
      .then((res) => {
        if (res?.data?.bookingSentSuccessfully) {
          const recentFranchisePath = getFranchiseDataFromLS(
            "recentFranchisePath"
          );
          const recentEstimateSubmissionPath = getFranchiseDataFromLS(
            "recentEstimateSubmissionPath"
          );

          if (matchLoc && recentFranchisePath) {
            if (recentFranchisePath === recentEstimateSubmissionPath) {
              const rgxforSlashes = /^\/|\/$/g;
              const franchisePath = recentFranchisePath?.replace(
                rgxforSlashes,
                ""
              );
              navigate(`/${franchisePath}/contact_success`);
            } else if (recentFranchisePath !== recentEstimateSubmissionPath) {
              const rgxforSlashes = /^\/|\/$/g;
              const franchisePath = recentEstimateSubmissionPath?.replace(
                rgxforSlashes,
                ""
              );
              navigate(`/${franchisePath}/contact_success`);
            }
          } else {
            navigate(`/contact_success`);
          }
          setTimeout(() => setIsSubmitting(false), 2000);
        } else {
          setIsSubmitting(false);
          setIsServiceDown(true);
          setServiceDownMessage(serviceError);
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log("AXIOS ERROR: ", err);
        setIsServiceDown(true);
        setServiceDownMessage(
          `Oops! Looks like something went wrong.Give us a call at (${franchiseContactNumber}) , and we'll be happy to help with your painitng project`
        );
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let payloadData = {};
    const isValidPhone = regxUSPhone.test(phoneNumber);
    const isValidEmail = regxEmail.test(email);
    const isValidZipcode = regxUSZipcode.test(zipCode);

    //Validating all the fields
    if (!isValidPhone || phoneNumber === "") {
      setIsErrorPhoneNo(true);
      setIsValidPhone(false);
    }
    if (!isValidEmail || email === "") {
      setIsErrorEmail(true);
      setIsValidEmail(false);
    }
    if (!isValidZipcode || zipCode === "") {
      setIsErrorZipCode(true);
    }
    if (fullnameValue === "") {
      setIsValidFirstName(false);
    }
    if (projectValue === "") {
      setIsPopulatedProject(false);
      setProjectValue({ aboutUs });
    }
    if (streetAddress === "") {
      setIsErrorStreetAddress(true);
      setIsPopulatedAddress(false);
    }
    if (cityValue === "") {
      setIsErrorCity(true);
      setIsPopulatedCity(false);
    }
    if (stateValue === "") {
      setIsErrorState(true);
      setIsPopulatedState(false);
    }
    if (
      isValidPhone === true ||
      isValidEmail === true ||
      isValidZipcode === true ||
      isValidFirstName === true ||
      isPopulatedAddress === true ||
      isPopulatedCity === true ||
      isPopulatedState === true
    ) {
      setIsErrorZipCode(false);
      setIsErrorPhoneNo(false);
      setIsErrorEmail(false);

      setIsErrorCity(false);
      setIsErrorState(false);
      setIsErrorStreetAddress(false);

      const payloadData = {
        customerName: fullnameValue,
        phoneNumber: phoneNumber,
        emailAddress: email,
        address: {
          street: streetAddress,
          city: cityValue,
          state: stateValue,
          zip: zipCode,
          country: "USA",
        },
        summary: projectValue,
        serviceTitanId: serviceTitanId,
        sourceApplicationName: "AcePaintingServices",
      };

      setIsSubmitting(true);

      handleApiCalls(payloadData);
    }
  };

  return (
    <div className="red-bg">
      <div className="container">
        <div className="header">
          <h3>{paintHeader}&#8482;</h3>
        </div>
        <div className="main-container">
          <form onSubmit={handleSubmit}>
            <div className="row1">
              <div className="fields">
                <label>
                  <span className="fieldNames">Full Name&#42;</span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  required
                  onChange={handleFirstNameChange}
                  value={fullnameValue}
                />
              </div>

              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.PhoneNumber?.Value}
                    &#42;
                  </span>
                </label>
                <input
                  type="tel"
                  className="inputField"
                  required
                  onChange={handlePhoneChange}
                  value={phoneNumber}
                />
                {isErrorPhoneNo && (
                  <span className="error-field">Phone No is Incorrect</span>
                )}
              </div>
              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.EmailAddress?.Value}
                    &#42;
                  </span>
                </label>
                <input
                  type="email"
                  className="inputField"
                  required
                  onChange={handleEmailChange}
                  value={email}
                />
                {isErrorEmail && (
                  <span className="error-field">Email is Incorrect</span>
                )}
              </div>
            </div>
            <div className="row2">
              <div className="fields full-width-field">
                <label>
                  <span className="fieldNames">Street address&#42;</span>
                </label>
                <input
                  type="text"
                  className="street-address"
                  required
                  onChange={handleAddressChange}
                  value={streetAddress}
                  size="20"
                />
                {isErrorStreetAddress && (
                  <span className="error-field">
                    StreetAddress is Incorrect
                  </span>
                )}
              </div>
            </div>
            <div className="row3">
              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.City?.Value}&#42;
                  </span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  required
                  onChange={handleCityChange}
                  value={cityValue}
                />
                {isErrorCity && (
                  <span className="error-field">City is Incorrect</span>
                )}
              </div>
              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.State?.Value}&#42;
                  </span>
                </label>

                <select
                  name="state_selection"
                  className="selectField"
                  onChange={handleStateChange}
                  value={stateValue}
                  required
                >
                  <option value="">State *</option>
                  {stateList?.map((state, index) => {
                    return (
                      <option value={state?.abbreviation} key={state?.name}>
                        {state?.abbreviation}
                      </option>
                    );
                  })}
                </select>
                {isErrorState && (
                  <span className="error-field">State is Incorrect</span>
                )}
              </div>
              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.ZipCode?.Value}&#42;
                  </span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  pattern="[0-9]{5}"
                  required
                  onChange={handleZipcodeChange}
                  value={zipCode}
                />
                {isErrorZipCode && (
                  <span className="error-field">Zipcode is Incorrect</span>
                )}
              </div>
            </div>
            <div className="row4">
              <div className="fields full-width-field">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.TellUsAbout?.Value}
                  </span>
                </label>
                <input
                  type="text"
                  className="aboutUs-input"
                  required
                  onChange={handleProjectChange}
                  value={projectValue}
                />
              </div>
            </div>
            <div className="submitBtn">
              <button className="btnSubmit">
                {!isSubmitting
                  ? franchiseDetails[0]?.EstimateOnlyTray?.SubmitButtonText
                      ?.Value
                    ? franchiseDetails[0]?.EstimateOnlyTray?.SubmitButtonText
                        ?.Value
                    : "Submit"
                  : "Please wait..."}
              </button>
            </div>
            <div
              className={`${isServiceDown ? "" : "hideField"}`}
              style={{ color: "#fff", textAlign: "center" }}
            >
              {serviceDownMessage}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CampaignForm;
