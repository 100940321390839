//regular expression constants

//US phone number
export const regxUSPhone =
  /(^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$)/;
//standard email
export const regxEmail =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
//US zipcode
export const regxUSZipcode = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

//custom logo for summerlin;
export const SUMMERLIN = "summerlin";

// const used in the serviceSearchOptions
export const tabNames = {
  BYROOM: "By Room",
  BYAREA: "By Area",
  BYSERVICE: "By Service",
};
export const hashParams = {
  BYROOM: "search-by-room",
  BYAREA: "search-by-area",
  BYSERVICE: "search-by-service",
};

export const multipartFormHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const serviceTitanHeader = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache",
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUB_KEY,
  },
}

//for JotFrom
export const aceBusinessName = "Ace Hardware Painting Services";

//read cookie value
export const readCookieValue = (name) => {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
};

//getting the results [] from the IGX api
export const getResultsArray = (inputData, expectedObj) => {
  const data = [];
  if (inputData?.results) {
    for (let i = 0, len = inputData?.results?.length; i < len; i++) {
      !expectedObj
        ? data.push(inputData?.results[i])
        : data.push(inputData?.results[i][expectedObj]);
    }
  } else if (Array.isArray(inputData)) {
    for (let i = 0, len = inputData?.length; i < len; i++) {
      data.push(inputData[i][expectedObj]);
    }
  }
  return data;
};

//for external URL check to open in new tab
//@param path or URL
export const isExternalUrl = (url) => {
  return /^https?:\/\//.test(url);
};

//this function used for creating dynamic routing
//output ex: /offices/north-metro-denver/why-choose-us will be /why-choose-us
//@param - path
export const extractPath = (pathString) => {
  let finalPath;
  if (!pathString) return;
  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }
  return `/${finalPath[finalPath?.length - 1]}`;
};

// checks if the recent franchise page's path visited matches the last franchise path already stored
// in local storage. if there's no match, only then does the last path get stored in local storage.
// this is so that we don't redundantly set an item into local storage that is already in local storage.
export const storeFranchiseDataToLS = (typeOfData, recentData) => {
  const setData = (type, data) => {
    if (localStorage.getItem(type) !== data) {
      return localStorage.setItem(type, data);
    }
  };
  return setData(typeOfData, recentData);
};

//get data from LocalStorage
export const getFranchiseDataFromLS = (typeOfData) => {
  if (localStorage.getItem(typeOfData?.toString())) {
    return localStorage.getItem(typeOfData?.toString());
  }
};

//remove item from local storage
export const deleteItemFromLS = (lsKey) => {
  return localStorage.removeItem(lsKey);
};




// this is meant to be used in components/FindACraftsman/Offices.js
// this returns an array of franchise ids from each local office so that it can be used as the
// value for the 'clientIds' field when getting the phone numbers for each client id from yo track
export const clientIdList = (localOffices) => {
  let clientIdArray = [];
  for (let i = 0; i < localOffices.length; i++) {
    clientIdArray.push(parseInt(localOffices[i]?.FranchiseId));
  }
  return clientIdArray;
};

// to get specific franchise id by checking if any of the local offices' franchise names matches
// the FranchiseName value of the current franchise page the user is on
export const clientIdSpecific = (localOffices, franchiseName) => {
  let clientIdArray = [];
  for (let i = 0; i < localOffices.length; i++) {
    if (localOffices[i]?.FranchiseName === franchiseName) {
      return parseInt(localOffices[i]?.FranchiseId);
    }
  }
  for (let i = 0; i < localOffices.length; i++) {
    clientIdArray.push(parseInt(localOffices[i]?.FranchiseId));
  }
  return clientIdArray;
};

//this function to compare and find the matched object in the api response
//and return the matched objet data.
//@param - inputData - Object, comparerData - array or string
//output - object
export const findAndGetObjData = (inputData, comparerData) => {
  let resultObj;
  if (inputData && Array.isArray(inputData)) {
    const arrayData = inputData?.filter((el) =>
      comparerData?.includes(el?.Schema)
    );
    resultObj = { ...arrayData[0] };
  } else
    resultObj =
      inputData &&
      Object.keys(inputData)
        .filter((key) => comparerData?.includes(key))
        .reduce((obj, key) => {
          obj[key] = inputData[key];
          return obj;
        }, {});
  return resultObj;
};

//@desc - formatting the phone number to US
//@param- phone string. Removing the non-digits from
//phoneNumberString).replace(/\D/g, '')
//@output - formatted phone, ex: 123-456-7890
export const formatPhone = (phoneNumberString) => {
  var onlyDigits = ("" + phoneNumberString).replace(/\D/g, "");
  var match = onlyDigits.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

// This function is for removing the extra path (like acehandymandss) from the main.js
//output ex: /acehandymandss/Services/:id/doors will be /Services/:id/doors
export const removeExtraPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return `/${finalPath.slice(2).join("/")}`;
};

// This function is for extracting the first path (like /acehandymandss)
//output ex: /acehandymandss/Services/:id/doors will give the output as /acehandymandss
export const ExtractingFirstPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return `/${finalPath[1]}`;
};

// added below for blogs page
export const extractingLastPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 1];
};

export const extractingSTLPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 2];
};

export const extractingTTLPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 3];
};

export const extractingFTLPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 4];
};

//for Franchise Home Page - Hours of operation
export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const weekConfig = {
  CLOSED: "Closed",
  BYAPPOINTMENT: "By Appointment",
};
//format the 24hs time to 12 hrs format
export const formatTimeTo12Hrs = (timeString) => {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  const mins = Number(minute < 59) ? minute : "00";
  const hourDigits = hour % 12 || 12;
  // const hours = Number(hourDigits < 10) ? "0" + hourDigits : hourDigits;
  const hours = hourDigits;
  return hours + ":" + mins + (hour < 12 ? " am" : " pm");
};

//For performance improvement, storing the api data which is not frequently changing to
//sessionStorage
export const loadApiData = (apiName) => {
  if (!apiName) return;
  try {
    const serializedData = sessionStorage.getItem(apiName);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (err) {
    return undefined;
  }
};

export const saveApiData = (apiName, apiData) => {
  if (!apiName) return;
  try {
    const serializedApiData = JSON.stringify(apiData);
    sessionStorage.setItem(apiName, serializedApiData);
  } catch (err) {
    return undefined;
  }
};

export const deleteItemFromSS = (ssKey) => {
  return sessionStorage.removeItem(ssKey);
};

//Keeping this bg-pattern image in utility as this image generically referenced in the css
//hence moving the css reference to JSX as inline style

//loading data from Session the common page background pattern image from x31 api
const x31Data = loadApiData("x31");
const patternImg =
  x31Data && x31Data[0]?.GlobalSettings?.GeneralPageBackground?.Value;
export const bgPatternTriangles = patternImg;

// Title and Meta Tag maps for blog pages and offices page
export const getMetaAndTileTags = (pathName) => {
  const urlMetaMap = {
    "/blog/questions-to-ask-a-painting-contractor-before-hiring-": {
      title:
        "5 Crucial Questions to Ask a Painting Contractor - Ace Hardware Painting Services Help",
      meta: "Hiring the right painting contractor is essential. Ace Hardware Painting Services helps you with the process of finding the right painting company the first time.",
    },
    "/blog/how-to-boost-curb-appeal-and-home-value": {
      title:
        "Enhance Curb Appeal and Home Value with Exterior Painting Projects",
      meta: "Home projects are a great way to boost your home's value and curb appeal. Ace Hardware Painting Services shares five exterior projects that will increase your home's value.",
    },
    "/blog/common-exterior-painting-mistakes": {
      title:
        "Avoid These Common Exterior Painting Mistakes - Ace Hardware Painting Services Tips ",
      meta: "	Painting your home's exterior might seem like an easy, straightforward project, but there are many little aspects that can be overlooked. Ace Hardware Painting Services helps homeowners like you through the process.",
    },
    "/blog/four-benefits-of-repainting-your-homes-exterior": {
      title:
        "Transform Your Home's Exterior with Paint - 4 Key Benefits of Repainting",
      meta: "There are many benefits to giving the exterior of your home a fresh coat of paint. Ace Hardware Painting Services reviews the top four benefits of painting your home's exterior that go beyond color.",
    },
    "/blog/when-is-the-best-time-to-paint-your-homes-exterior": {
      title: "When is the Best Time to Paint the Exterior of My Home",
      meta: "Ace Hardware Painting Services outlines when is the best time to paint the exterior of your home including how weather affects paint application, weather to avoid when painting the exterior of your home, and more.",
    },
    "/blog/5-signs-your-exterior-needs-a-repaint": {
      title: "5 Signs The Exterior of Your Home Needs a Repaint",
      meta: "Caring for your home's exterior is important for maintaining its longevity, value, and the protection it provides. Ace Hardware Painting Services shares the top five things that can indicate when it's time for a repaint.",
    },
    "/blog/how-to-choose-the-right-paint-color-for-your-home": {
      title:
        "Mastering Home Paint Color Selection: Tips for Choosing the Perfect Shade",
      meta: " One of the biggest issues homeowners have when updating their homes is choosing the right paint colors. Ace Hardware Painting Services shares a guide to help you make the best paint color choices for your home!",
    },
    "/offices": {
      title:
        "Ace Hardware Painting Services Local Offices - Find a Local Painting Company",
      meta: "Ace Hardware Painting Services strives to change what you can expect with your paint project. Our Goal is to have a helpful, service-first relationship with our customers always. Find your local Ace Hardware Painting Services now.",
    },
  };

  return urlMetaMap[pathName];
};

// Utility function to generate unique id when there is no id or duplicate Id
export const generateUniqueId = (idPrefix) => {
  let uniqueId = "";
  const uniqueNumber = Date.now().toString(32) + Math.random().toString(16);
  uniqueId = `${idPrefix}-${uniqueNumber}`;
  return uniqueId;
};

//function to determine NoIndexTag addition
export const setNoIndexTag = () => {
  let setTag = false;
  let noIndexPages = ["campaign"];
  setTag = noIndexPages.some((page) => window.location.pathname.includes(page));
  return setTag;
};

export const formatPhoneforCampaign = (phoneNumberString) => {
  var onlyDigits = ("" + phoneNumberString).replace(/\D/g, "");
  var phoneDigit = onlyDigits.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (phoneDigit) {
    return (
      "(" +
      phoneDigit?.[1] +
      ")" +
      " " +
      phoneDigit?.[2] +
      "-" +
      phoneDigit?.[3]
    );
  }
  return null;
};

export const extractingSTLPathFranchisePage = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 3];
};

export const extractingSTLPathFranchise = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 1];
};

export const getScheduleEstimateTabTray = (franchiseHomeData) => {
  let scheduleTray;
  if (!franchiseHomeData) return;
  scheduleTray = franchiseHomeData[0]?.Trays?.find(
    (tray) =>
      tray?.TrayName === "ScheduleandEstimateTabTray" ||
      tray?.TrayName === "ScheduleandEstimateTray"
  );
  if (!scheduleTray) {
    const serviceCityTabIndex = franchiseHomeData[0]?.ServiceCitySEO?.findIndex(
      (city) => city?.template
    );

    scheduleTray =
      franchiseHomeData[0]?.ServiceCitySEO[serviceCityTabIndex]?.template
        ?.ScheduleandEstimateTabTray;
  }
  return scheduleTray;
};
