import React, { useState, useEffect } from "react";
import { Markup } from "interweave";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { serviceTitanURL, ingeniuxURL } from "../../config/urls";
import {
  regxEmail,
  regxUSPhone,
  regxUSZipcode,
  serviceTitanHeader,
  getFranchiseDataFromLS,
  storeFranchiseDataToLS,
  deleteItemFromLS,
  generateUniqueId,
} from "../../config/utility";
import LocalOfficeNonExistent from "../common/LocalOfficeNonExistent";
import {
  FORM_START_GET_AN_ESTIMATE,
  FORM_SUBMIT,
  trackAnalytics,
} from "../../config/gTrack";
import { stateList } from "../../constants/stateList";
import "./index.css";
import "../common/getEstimate.css";

const GetEstimateJotFrom = ({
  viewPortWidth = "",
  franchiseDataToGetEstimate: { franchiseId, franchiseName, stateAbbr } = {},
  contentData: estimateData,
  currentTab,
  handymanmatters,
  isNewFranchise,
  newFranchiseHeader,
  newFranchiseSubheading,
  successModalTriggeringFunc,
}) => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [emailValue, setEmailValue] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [zipcodeValue, setZipcodeValue] = useState("");
  const [isValidZipcode, setIsValidZipcode] = useState(true);
  const [zipLenErr, setZipLenErr] = useState(false);
  const [fullnameValue, setFullnameValue] = useState("");
  const [isPopulatedFullname, setIsPopulatedFullname] = useState(true);
  const [projectValue, setProjectValue] = useState("");
  const [isPopulatedProject, setIsPopulatedProject] = useState(true);
  const [isErrorFree, setIsErrorFree] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isServiceDown, setIsServiceDown] = useState(false);
  const [serviceDownMessage, setServiceDownMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formStart, setFormStart] = useState(false);
  const [matchLoc, setMatchLoc] = useState({ locUrl: "", locName: "" });
  const [streetAddressValue, setStreetAddressValue] = useState("");
  const [isPopulatedAddress, setIsPopulatedAddress] = useState(true);
  const [cityValue, setCityValue] = useState("");
  const [isPopulatedCity, setIsPopulatedCity] = useState(true);
  const [stateValue, setStateValue] = useState("");
  const [isPopulatedState, setIsPopulatedState] = useState(true);
  const [serviceTitanId, setServiceTitanId] = useState("");
  const [franchiseContactNumber,setFranchiseContactNumber]=useState('1-855-PAINT80');

  const corpClientId = process.env.REACT_APP_CORP_CLIENTID;
  const corpEmailId = process.env.REACT_APP_JOTFORM_EMAIL;

  useEffect(() => {
    isFormErrorFree();
  }, [
    isValidEmail,
    isValidPhone,
    isValidZipcode,
    isPopulatedFullname,
    isPopulatedProject,
    isPopulatedAddress,
    isPopulatedCity,
    isPopulatedState,
  ]);

  const getFormSubmissionLocationDetails = () => {
    let origin = !franchiseName ? matchLoc.locName : franchiseName;
    let url = `${pathname}${hash}`;
    if (Boolean(origin)) {
      return { locationName: origin, url };
    }
    return { locationName: "Corporate", url };
  };

  const trackFormStartStatusForGA = (e) => {
    if (!formStart) {
      setFormStart(true);
      e.stopPropagation();
      const { locationName, url } = getFormSubmissionLocationDetails();
      trackAnalytics(FORM_START_GET_AN_ESTIMATE, locationName, url);
    }
  };

  const handleFullnameChange = (e) => {
    setFullnameValue(e.target.value);
    trackFormStartStatusForGA(e);
  };

  const handleFullnameFocus = (e) => {
    const fullName = e.target;
    const label = document.querySelector('label[for="' + fullName.id + '"]');
    if (!fullName.value || fullName.value === label.innerHTML) {
      setIsPopulatedFullname(true);
      setFullnameValue("");
    }
  };

  const handleAddressChange = (e) => {
    setStreetAddressValue(e.target.value);
  };

  const handleAddressFocus = (e) => {
    const address = e.target.value;
    const label = document.querySelector("#address_label");
    if (!address || address === label.innerHTML) {
      setIsPopulatedAddress(true);
      setStreetAddressValue("");
    }
  };

  const handleCityChange = (e) => {
    setCityValue(e.target.value);
  };

  const handleCityFocus = (e) => {
    const city = e.target.value;
    const label = document.querySelector("#city_label");
    if (!city || city === label.innerHTML) {
      setIsPopulatedCity(true);
      setCityValue("");
    }
  };

  const handleStateChange = (e) => {
    setStateValue(e.target.value);
  };

  const handleStateFocus = (e) => {
    const state = e.target.value;
    const label = document.querySelector("#state_label");
    if (!state || state === label.innerHTML) {
      setIsPopulatedState(true);
      setStateValue("");
    }
  };

  const formatPhoneWithBracket = (phone) => {
    const x = phone?.replace(/\D/g, "");
    if (x.length > 3 && x.length < 7) {
      return "(" + x.slice(0, 3) + ") " + x.slice(3);
    } else if (x.length >= 7) {
      return "(" + x.slice(0, 3) + ") " + x.slice(3, 6) + "-" + x.slice(6);
    }
    return x;
  };

  const handlePhoneChange = (e) => {
    let formattedValue = formatPhoneWithBracket(e.target.value);

    if (formattedValue.length > 14) {
      formattedValue = formattedValue.slice(0, 14);
    }
    if (!isValidPhone) {
      setIsValidPhone(true);
    }
    setPhoneValue(formattedValue);
    trackFormStartStatusForGA(e);
  };

  const handlePhoneFocus = (e) => {
    const phoneVal = e.target;
    const label = document.querySelector('label[for="' + phoneVal.id + '"]');
    if (!phoneVal.value || phoneVal.value === label.innerHTML) {
      setIsValidPhone(true);
      setPhoneValue("");
    }
  };

  const handleEmailChange = (e) => {
    if (!isValidEmail) {
      setIsValidEmail(true);
    }
    setEmailValue(e.target.value);
    trackFormStartStatusForGA(e);
  };
  const handleEmailFocus = (e) => {
    const emailVal = e.target;
    const label = document.querySelector('label[for="' + emailVal.id + '"]');

    if (!emailVal.value || emailVal.value === label.innerHTML) {
      setIsValidEmail(true);
      setEmailValue("");
    }
  };

  var matchingZipcode = "";

  const handleZipcodeChange = (e) => {
    e.preventDefault();

    let isValidZip;
    const zipVal = e.target.value;
    setZipcodeValue(zipVal);
    trackFormStartStatusForGA(e);
    isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipVal);

    if (!isValidZip || zipVal === "") {
      setZipLenErr(false);
    }
    for (let i = 0; i < estimateData?.zipCodeList?.length; i++) {
      for (let j = 0; j < estimateData?.zipCodeList[i]?.length; j++) {
        if (zipVal === estimateData?.zipCodeList[i][j]) {
          matchingZipcode = zipVal;
        }
      }
    }

    if (matchingZipcode !== "") {
      setShowModal(false);
    } else if (matchingZipcode === "" && isValidZip) {
      setShowModal(true);
      // boolean set to true to show modal
    }

    if (!zipVal) setZipLenErr(false);
    else if (zipVal?.length === 5 && isValidZipcode) {
      //checking the LocalOffices array contains any match with user entered ZIPCODE
      const locationMatch = estimateData?.localOfficeList?.filter(
        (office) =>
          office?.OwnedZipcodes?.some((zip) => zip === zipVal) ||
          office?.AllowedZipcodes?.some((zip) => zip === zipVal)
      );
      const zipLocationMatch =
        locationMatch && locationMatch[0]?.HomePageLink?.URL;
      const zipLocName = locationMatch && locationMatch[0]?.FranchiseName;
      zipLocationMatch &&
        setMatchLoc((prevState) => ({
          ...prevState,
          locUrl: zipLocationMatch,
          locName: zipLocName,
        }));
      if (zipLocationMatch)
        sessionStorage.setItem("locationMatch", zipLocationMatch);
      else sessionStorage.setItem("locationMatch", zipLocationMatch);
      //deleting the recent submission data in the case of no location match scenario
      if (zipLocationMatch) {
        storeFranchiseDataToLS(
          "recentEstimateSubmissionPath",
          `/${zipLocationMatch}`
        );
        // Call to fetch dynamic ServiceTitan Id from Franchises
        axios
          .get(`${ingeniuxURL}api/page?path=${zipLocationMatch}`)
          .then((res) => {
            const franchiseCorpControl =
              res?.data?.results[0]?.FranchiseHomePage
                ?.FranchiseCorporateControl;
            setServiceTitanId(franchiseCorpControl?.ServiceTitanID?.Value);
            setFranchiseContactNumber(franchiseCorpControl?.PhoneNumber?.Value);
          })
          .catch((err) => console.log(err));
      } else deleteItemFromLS("recentEstimateSubmissionPath");

      setZipLenErr(false);
    } else {
      setZipLenErr(true);
    }
  };

  const handleZipcodeFocus = (e) => {
    const zipVal = e.target;
    const label = document.querySelector('label[for="' + zipVal.id + '"]');
    if (!zipVal.value || zipVal.value === label.innerHTML) {
      setIsValidZipcode(true);
      setZipcodeValue("");
    }
  };

  const handleProjectChange = (e) => {
    setProjectValue(e.target.value);
    trackFormStartStatusForGA(e);
  };
  const handleProjectFocus = (e) => {
    const prjVal = e.target;
    const label = document.querySelector('label[for="' + prjVal.id + '"]');
    if (!prjVal.value || prjVal.value === label.innerHTML) {
      setIsPopulatedProject(true);
      setProjectValue("");
    }
  };

  //GA Form Submit tracker
  const handleWindowTracker = (e, gaEventName, franchiseInfo, pageUrl) => {
    let origin = !franchiseInfo ? matchLoc.locName : franchiseInfo;
    let url = `${pageUrl}${hash}`;
    e.stopPropagation();
    trackAnalytics(gaEventName, origin, url);
  };

  const isFormErrorFree = () => {
    if (
      isValidPhone === false ||
      isValidEmail === false ||
      isValidZipcode === false ||
      isPopulatedFullname === false ||
      isPopulatedProject === false ||
      isPopulatedAddress === false ||
      isPopulatedCity === false ||
      isPopulatedState === false
    ) {
      setIsErrorFree(false);
    } else {
      setIsErrorFree(true);
    }
  };

  const handleSubmit = (e) => {
    // prevent page from refreshing
    e.preventDefault();
    setIsServiceDown(false);
    setServiceDownMessage("");
    const isValidPhone = regxUSPhone.test(phoneValue);
    const isValidEmail = regxEmail.test(emailValue);
    const isValidZipcode = regxUSZipcode.test(zipcodeValue);
    let isFormValidForSubmission = true;
    if (!isValidPhone || phoneValue === "") {
      setIsValidPhone(false);
      isFormValidForSubmission = false;
    }
    if (!isValidEmail || emailValue === "") {
      setIsValidEmail(false);
      isFormValidForSubmission = false;
    }
    if (!isValidZipcode || zipcodeValue === "") {
      setIsValidZipcode(false);
      isFormValidForSubmission = false;
    }
    if (!fullnameValue || fullnameValue === "") {
      setIsPopulatedFullname(false);
      isFormValidForSubmission = false;
    }
    if (!streetAddressValue || streetAddressValue === "") {
      setIsPopulatedAddress(false);
      isFormValidForSubmission = false;
    }
    if (!cityValue || cityValue === "") {
      setIsPopulatedCity(false);
      isFormValidForSubmission = false;
    }
    if (!stateValue || stateValue === "") {
      setIsPopulatedState(false);
      isFormValidForSubmission = false;
    }
    if (projectValue === "") {
      setIsPopulatedProject(false);
      isFormValidForSubmission = false;
    }

    if (isErrorFree && isFormValidForSubmission) {
      const serviceTitanTestSubmissionData = {
        customerName: fullnameValue,
        phoneNumber: phoneValue,
        emailAddress: emailValue,
        address: {
          street: streetAddressValue,
          city: cityValue,
          state: stateValue,
          zip: zipcodeValue,
          country: "USA",
        },
        summary: projectValue,
        serviceTitanId: serviceTitanId,
        sourceApplicationName: "AcePaintingServices",
      };

      setIsSubmitting(true);
      setIsDisabled(true);
      //GA form submit tracker
      handleWindowTracker(e, FORM_SUBMIT, franchiseName, pathname);
      axios
        .post(
          serviceTitanURL,
          serviceTitanTestSubmissionData,
          serviceTitanHeader
        )
        .then((res) => {
          if (res?.data?.bookingSentSuccessfully) {
            //redirection to contact success page
            const recentFranchisePath = getFranchiseDataFromLS(
              "recentFranchisePath"
            );
            const recentEstimateSubmissionPath = getFranchiseDataFromLS(
              "recentEstimateSubmissionPath"
            );

            if (matchLoc.locUrl && recentFranchisePath && !isNewFranchise) {
              if (recentFranchisePath === recentEstimateSubmissionPath) {
                const rgxforSlashes = /^\/|\/$/g;
                const franchisePath = recentFranchisePath?.replace(
                  rgxforSlashes,
                  ""
                );
                navigate(`/${franchisePath}/contact_success`);
              } else if (recentFranchisePath !== recentEstimateSubmissionPath) {
                const rgxforSlashes = /^\/|\/$/g;
                const franchisePath = recentEstimateSubmissionPath?.replace(
                  rgxforSlashes,
                  ""
                );
                navigate(`/${franchisePath}/contact_success`);
              }
            } else if (isNewFranchise) {
              successModalTriggeringFunc(true);
            } else {
              navigate(`/contact_success`);
            }
            setTimeout(() => setIsSubmitting(false), 2000);
          } else {
            setIsSubmitting(false);
            setIsServiceDown(true);
            setIsDisabled(true);
            setServiceDownMessage(
              "Service Unavailable. Please try again later"
            );
          }
        })
        .catch((err) => {
          setIsDisabled(true);
          setIsSubmitting(false);
          console.log("AXIOS ERROR: ", err);
          setIsServiceDown(true);
          setServiceDownMessage(
            `Oops! Looks like something went wrong.Give us a call at (${franchiseContactNumber}) , and we'll be happy to help with your painitng project`
          );
        });
    }
  };
  const submitButtonId = generateUniqueId("submit-btn");
  return (
    <>
      <div
        className={
          isNewFranchise
            ? "new-Franchise-Content col-md-12 col-dd-6 content"
            : "col-md-12 col-dd-6 content"
        }
      >
        <div className="get-quote-wrap">
          <div className="form" id="quote-form">
            <h2 className={isNewFranchise ? "estimate-header" : ""}>
              {isNewFranchise
                ? newFranchiseHeader
                : currentTab === "GET A FREE COLOR CONSULTATION"
                ? "Get A Free Color Consultation"
                : estimateData?.DemographicsFormHeading?.Value}
            </h2>
            {isNewFranchise && (
              <p className="subheading">{newFranchiseSubheading}</p>
            )}
            <div id="field-wrap" className="col-sd-12 col-md-12 col-dd-12">
              <form
                className="jotform-form photo-quote-form"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
                name="form_91254806635157"
              >
                <div role="main" className="form-all">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                    }}
                    className={`${
                      viewPortWidth ? "" : "jot-form-group-margin"
                    } ${isNewFranchise ? "new-franchise-main-div" : ""}`}
                  >
                    <div
                      className={`col-sd-12  col-md-12 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${isNewFranchise ? "input-fix-width" : ""}`}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_3"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_3"
                          htmlFor="input_3"
                        >
                          Full Name *
                        </label>
                        <div
                          id="cid_3"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedFullname ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_3"
                            name="q3_fullName"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required]"
                                : "form-textbox validate[required]"
                            }
                            size="20"
                            placeholder="Full Name *"
                            area-label="Full Name"
                            data-component="textbox"
                            onChange={handleFullnameChange}
                            onFocus={handleFullnameFocus}
                            value={fullnameValue}
                          />
                          <p
                            className={`${
                              isPopulatedFullname ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                              margin: viewPortWidth ? "0 0 10px 0" : "0",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                      justifyContent: "space-around",
                    }}
                    className={`${
                      viewPortWidth ? "" : "jot-form-group-margin"
                    } ${isNewFranchise ? "new-franchise-main-div" : ""}`}
                  >
                    <div
                      className={`col-sd-12  col-md-6 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${isNewFranchise ? "input-fix-width" : ""}`}
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_5"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_5"
                          htmlFor="input_5"
                        >
                          Email Address *
                        </label>
                        <div
                          id="cid_5"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidEmail ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_5"
                            name="q5_emailAddress"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required] franchise-email"
                                : "form-textbox validate[required]"
                            }
                            size="20"
                            placeholder="Email Address *"
                            aria-label="Email Address"
                            data-component="textbox"
                            onChange={handleEmailChange}
                            onFocus={handleEmailFocus}
                            value={emailValue}
                          />
                          <p
                            className={`${isValidEmail ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            {emailValue
                              ? "Please enter a valid emailID"
                              : "This field is required."}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sd-12  col-md-6 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${isNewFranchise ? "input-fix-width" : ""}`}
                      style={{ marginLeft: viewPortWidth ? "0" : "18px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_4"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_4"
                          htmlFor="input_4"
                        >
                          Phone Number *
                        </label>
                        <div
                          id="cid_4"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidPhone ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_4"
                            name="q4_phoneNumber"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required]"
                                : "form-textbox validate[required]"
                            }
                            size="20"
                            placeholder="Phone Number *"
                            aria-label="Phone Number"
                            data-component="textbox"
                            onChange={handlePhoneChange}
                            onFocus={handlePhoneFocus}
                            value={phoneValue}
                          />
                          <p
                            className={`${isValidPhone ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            {phoneValue
                              ? "Please enter a valid phone number"
                              : "This field is required."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                      justifyContent: "space-around",
                    }}
                    className={`${
                      viewPortWidth ? "" : "jot-form-group-margin"
                    } ${isNewFranchise ? "new-franchise-main-div" : ""}`}
                  >
                    <div
                      className={`col-sd-12  col-md-12 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${isNewFranchise ? "input-fix-width" : ""}`}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="jf_address"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="address_label"
                          htmlFor="street_address"
                        >
                          Street address *
                        </label>
                        <div
                          id="jf_street_address"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedAddress ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="street_address"
                            name="street_address_field"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required]"
                                : "form-textbox validate[required]"
                            }
                            size="20"
                            placeholder="Street address *"
                            area-label="Street address"
                            data-component="textbox"
                            onChange={handleAddressChange}
                            onFocus={handleAddressFocus}
                            value={streetAddressValue}
                          />
                          <p
                            className={`${
                              isPopulatedAddress ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                              margin: viewPortWidth ? "0 0 10px 0" : "0",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                      justifyContent: "space-around",
                    }}
                    className={`${
                      viewPortWidth ? "" : "jot-form-group-margin"
                    } ${isNewFranchise ? "new-franchise-main-div" : ""}`}
                  >
                    <div
                      className={`col-sd-12  col-md-5 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${
                        isNewFranchise
                          ? "input-fix-width input-flex-setting"
                          : ""
                      }`}
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="jf_city"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="city_label"
                          htmlFor="city"
                        >
                          City *
                        </label>
                        <div
                          id="jf_city_id"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedCity ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="city"
                            name="jf_city_field"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required]"
                                : "form-textbox validate[required]"
                            }
                            placeholder="City *"
                            aria-label="City"
                            data-component="textbox"
                            onChange={handleCityChange}
                            onFocus={handleCityFocus}
                            value={cityValue}
                          />
                          <p
                            className={`${isPopulatedCity ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sd-12  col-md-3 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${
                        isNewFranchise
                          ? "input-fix-width input-flex-setting"
                          : ""
                      }`}
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="jf_state"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="state_label"
                          htmlFor="state"
                        >
                          State *
                        </label>
                        <div
                          id="jf_state_id"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedState ? "" : "error"
                          }`}
                        >
                          <select
                            name="state_selection"
                            id="state"
                            style={{ padding: "18px 15px" }}
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required]"
                                : ""
                            }
                            onChange={handleStateChange}
                            onFocus={handleStateFocus}
                            value={stateValue}
                          >
                            <option value="">State *</option>
                            {stateList?.map((state, index) => {
                              return (
                                <option
                                  value={state?.abbreviation}
                                  key={state?.name}
                                >
                                  {state?.abbreviation}
                                </option>
                              );
                            })}
                          </select>

                          <p
                            className={`${isPopulatedState ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sd-12  col-md-4 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${
                        isNewFranchise
                          ? "input-fix-width input-flex-setting"
                          : ""
                      }`}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_6"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_6"
                          htmlFor="input_6"
                        >
                          {estimateData?.ZipCode?.Value}
                        </label>
                        <div
                          id="cid_6"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidZipcode ? "" : "error"
                          } ${zipLenErr ? "error" : ""}`}
                        >
                          <input
                            type="text"
                            id="input_6"
                            name="q6_zipCode"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required, Zipcode]"
                                : "form-textbox validate[required, Zipcode]"
                            }
                            size="20"
                            placeholder={estimateData?.ZipCode?.Value}
                            area-label="Zip Code"
                            data-component="textbox"
                            onChange={handleZipcodeChange}
                            onFocus={handleZipcodeFocus}
                            value={zipcodeValue}
                            maxLength="5"
                            autoComplete="off"
                          />
                          <p
                            className={`${isValidZipcode ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                          <p
                            className={`${!zipLenErr ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field must contain a 5-digit zip code
                          </p>
                        </div>
                      </div>
                      <LocalOfficeNonExistent
                        showModal={showModal}
                        setShowModal={setShowModal}
                      />
                    </div>
                  </div>
                  <div className={viewPortWidth ? "" : "jot-form-group-margin"}>
                    <div
                      className={`col-sd-12 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      }`}
                      style={{ width: "100%", paddingRight: "0" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textarea"
                        id="id_7"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_7"
                          htmlFor="input_7"
                        >
                          {estimateData?.TellUsAbout?.Value}
                        </label>
                        <div
                          id="cid_7"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedProject ? "" : "error"
                          }`}
                        >
                          <textarea
                            id="input_7"
                            className={
                              isNewFranchise
                                ? "new-franchise-text-box form-textarea validate[required] custom-hint-group form-custom-hint"
                                : "form-textarea validate[required] custom-hint-group form-custom-hint"
                            }
                            required=""
                            name="q7_tellUs"
                            cols="40"
                            rows="6"
                            data-component="textarea"
                            aria-label="Tell us about your project*"
                            data-customhint="Tell us about your project"
                            customhinted="true"
                            placeholder={estimateData?.TellUsAbout?.Value}
                            spellCheck="false"
                            onChange={handleProjectChange}
                            onFocus={handleProjectFocus}
                            value={projectValue}
                          ></textarea>
                          <p
                            className={`${
                              isPopulatedProject ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "1.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sd-12 " style={{ width: "100%" }}>
                    <div
                      className="form-line"
                      data-type="control_button"
                      id="id_2"
                    >
                      <div
                        id="cid_2"
                        className={
                          isNewFranchise
                            ? "form-input-wide new-franchise-input-btn"
                            : "form-input-wide"
                        }
                      >
                        <div className="form-buttons-wrapper">
                          <button
                            id={submitButtonId}
                            type="submit"
                            className={
                              isDisabled
                                ? "form-submit-button disable-btn simpli-btn simpli-contact"
                                : "form-submit-button submit btn simpli-btn simpli-contact"
                            }
                            data-component="button"
                            disabled={isDisabled}
                          >
                            {isNewFranchise
                              ? !isSubmitting && !isDisabled
                                ? "GET YOUR FREE ESTIMATE"
                                : "Please wait..."
                              : !isSubmitting && !isDisabled
                              ? estimateData?.SubmitButtonText?.Value
                                ? estimateData?.SubmitButtonText?.Value
                                : "Submit"
                              : "Please wait..."}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${isErrorFree ? "hideField" : ""}`}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    There are errors on the form. Please fix them before
                    continuing.
                  </div>
                  <div
                    className={`${isServiceDown ? "" : "hideField"}`}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    {serviceDownMessage}
                  </div>
                </div>
              </form>
              {handymanmatters && (
                <div
                  style={{
                    color: "#000",
                    lineHeight: "1.2em",
                    marginTop: "90px",
                    textAlign: "center",
                  }}
                >
                  <Markup content={handymanmatters} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetEstimateJotFrom;
