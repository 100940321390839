import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Markup } from "interweave";
import { useLocation } from "react-router-dom";

import MenuBar from "./MenuBar";
import DynamicLinkList from "./DynamicLinks";
import LastFranchiseVisitedRibbon from "./LastFranchiseVisitedRibbon";
import SvgPhoneIcon from "./SvgPhoneIcon";
import {
  getFranchiseDataFromLS,
  formatPhone,
  isExternalUrl,
} from "../../config/utility";
import { ace4c } from "./ErrorBoundary/images";
import { ingeniuxURL } from "../../config/urls";
import { CLICK_TO_CALL, trackAnalytics } from "../../config/gTrack";
import "./header.css";

const Header = ({
  alertContent,
  headerContent,
  phoneNum,
  tel,
  recentFranchise,
}) => {
  const acePaintId = process.env.REACT_APP_ACEPAINTID;
  const corpClientId = process.env.REACT_APP_CORP_CLIENTID;

  const headerArray = [];
  const partition = (array) => {
    if (array) {
      let pass = [];
      let fail = [];
      array.forEach((element) => {
        if (element?.Name === "TopLink") {
          pass.push(element);
        } else {
          fail.push(element);
        }
      });
      return [pass, fail];
    }
  };
  let headerData = headerContent?.MenuTab?.map((headerData) => {
    if (Array.isArray(headerData?.Page)) {
      const [pass, fail] = partition(headerData?.Page);
      headerArray.push({
        Link: pass[0]?.Link,
        URL: pass[0]?.URL,
        SubLink: fail,
      });
    } else {
      if (headerData?.Page?.Name === "TopLink") {
        headerArray.push({
          Link: headerData?.Page?.Link,
          URL: headerData?.Page?.URL,
        });
      }
    }
    return headerArray;
  });

  const [showNav, setShowNav] = useState(true);
  const [showSwapPhone, setSwapPhone] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    let localPhone;

    let intervalId = setInterval(() => {
      localPhone = getFranchiseDataFromLS("recentFranchisPhone");
      if (localPhone) {
        setSwapPhone(localPhone);
        clearInterval(intervalId);
      }
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  //GA Phone click tracker
  const handleWindowTracker = (e, gaEventName, originInfo, pageUrl) => {
    e.stopPropagation();
    trackAnalytics(gaEventName, originInfo, pageUrl);
  };

  return (
    <div>
      <div id="sticky-wrapper" className="sticky-wrapper is-sticky">
        <header style={{ width: "100%" }}>
          {alertContent?.DisplayAlert?.Value === "True" && (
            <div
              id="alert-bar"
              style={{
                display: `${
                  alertContent?.AlertText?.Value === "" ||
                  !alertContent?.AlertText?.Value
                    ? "none"
                    : "block"
                }`,
              }}
            >
              <div className="home-container fluid max">
                <div className="row">
                  <div className="col-12">
                    <center>
                      <a target="_blank" href={alertContent?.Page?.URL}>
                        <Markup content={alertContent?.AlertText?.Value} />
                      </a>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          )}

          {getFranchiseDataFromLS("recentFranchisePath") !== undefined && (
            <LastFranchiseVisitedRibbon recentFranchise={recentFranchise} />
          )}

          <div className="yo-local" style={{ display: "none" }}>
            <div className="topbar-wrap row twelve">
              <div className="home-container">
                <div className="topbar col-sd-12 col-md-12">
                  <div className="name yo-local-city"></div>
                  <div className="local-nav yo-local-nav"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="head-wrap home-container row twelve">
            <div className="logo col-sd-5 col-md-6 col-dd-3">
              {headerContent ? (
                <a href="/">
                  {headerContent?.HeaderLogo?.Value && (
                    <img
                      src={`${ingeniuxURL}${headerContent?.HeaderLogo?.Value}`}
                      style={{ maxWidth: "87%" }}
                      width="200"
                      height="90"
                      alt={
                        headerContent?.HeaderLogo?.AlternateText
                          ? headerContent?.HeaderLogo?.AlternateText
                          : "Ace Hardware Paint logo"
                      }
                      title={
                        headerContent?.HeaderLogo?.AlternateText
                          ? headerContent?.HeaderLogo?.AlternateText
                          : "Ace Hardware Paint logo"
                      }
                    />
                  )}
                </a>
              ) : (
                ""
              )}
            </div>
            <div>
              <a
                href="https://acehardware.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="nav-logo-img-cover"
                  src={ace4c}
                  alt="Ace Handyman Services Logo"
                  width="200"
                  height="60"
                />
              </a>
            </div>
            <div className="header-top-wrap col-sd-7 col-md-6 col-dd-9">
              <div className="header-top-container">
                <div className="header-top" style={{ textAlign: "center" }}>
                  <div className="anchors">
                    <Link
                      to={`/#${headerContent?.Page[1]?.Name}`}
                      data-tab="get-a-quote"
                    >
                      {headerContent?.GetEstimateIcon?.Value && (
                        <img
                          src={`${ingeniuxURL}${headerContent?.GetEstimateIcon?.Value}`}
                          style={{ maxWidth: "30px", maxHeight: "30px" }}
                          width="30"
                          height="30"
                          alt={
                            headerContent?.GetEstimateIcon?.AlternateText
                              ? headerContent?.GetEstimateIcon?.AlternateText
                              : "Get an Estimate"
                          }
                          title={
                            headerContent?.GetEstimateIcon?.AlternateText
                              ? headerContent?.GetEstimateIcon?.AlternateText
                              : "Get an Estimate"
                          }
                        />
                      )}

                      <span>GET A FREE ESTIMATE</span>
                    </Link>
                  </div>
                  {headerContent && (
                    <div className="header-phone" title="contact-us-at">
                      <div className="yo-local" style={{ display: "none" }}>
                        <span className="yo-local-phone yo-local-href-phone"></span>
                      </div>
                      <div
                        className={`yo-corporate ${
                          getFranchiseDataFromLS("recentFranchisePath") !==
                          undefined
                            ? ""
                            : "yo-corporate-without-franchise-ribbon"
                        }`}
                      >
                        <SvgPhoneIcon />
                        <a
                          href={
                            showSwapPhone
                              ? `tel:${showSwapPhone}`
                              : `tel:${tel?.Value}`
                          }
                          onClick={(e) => {
                            handleWindowTracker(
                              e,
                              CLICK_TO_CALL,
                              "Corporate",
                              pathname
                            );
                          }}
                        >
                          {showSwapPhone
                            ? formatPhone(showSwapPhone)
                            : phoneNum?.Value}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="page-wrapper">
                <div id="mobile-nav">
                  <i
                    className="fa-solid fa-bars"
                    onClick={() => setShowNav(!showNav)}
                  >
                    <MenuBar
                      showNav={showNav}
                      setShowNav={setShowNav}
                      headerContent={headerData && headerData[0]}
                    >
                      <div
                        id="content-wrapper"
                        style={{
                          left: showNav ? "280px" : "65px",
                          width: showNav
                            ? "calc(100% - 280px)"
                            : "calc(100% - 55px)",
                        }}
                      ></div>
                    </MenuBar>
                  </i>
                </div>
              </div>

              <nav className="topnav">
                <ul className="first-level">
                  {headerData &&
                    headerData[0]?.map((menuItem, index) => {
                      const isExternal = isExternalUrl(menuItem?.URL);
                      return (
                        <li key={index}>
                          <DynamicLinkList
                            pagePath={
                              isExternal
                                ? `${menuItem?.URL}`
                                : `/${menuItem?.URL}`
                            }
                            isExternal={isExternal}
                            pageApiUrl={`${menuItem?.URL}`}
                            pageName={menuItem?.Link}
                          />
                          {menuItem?.SubLink && (
                            <div className="dropdown">
                              <div className="home-container">
                                <div className="header col-md-4">
                                  <DynamicLinkList
                                    pagePath={`/${menuItem?.URL}`}
                                    pageApiUrl={`/${menuItem?.URL}`}
                                    pageName={menuItem?.Link}
                                  />
                                </div>
                                <div className="dropdown-nav col-sd-12 col-md-8">
                                  <ul>
                                    {menuItem?.SubLink?.map(
                                      (subLink, index) => {
                                        return (
                                          <li key={index}>
                                            <DynamicLinkList
                                              pagePath={`/${subLink?.URL}`}
                                              pageApiUrl={`/${subLink?.URL}`}
                                              pageName={subLink?.Link}
                                            />
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                      );
                    })}

                  <li className="get-estimate-menu-header-cta">
                    <Link
                      to={`/#${headerContent?.Page[1]?.Name}`}
                      data-tab="get-a-quote"
                    >
                      <span>Get A Free Estimate</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
